// -------------------------
// --- You can add your variables to overwrite default values.
// -------------------------

// Uncomment one of the following line if you want to get appropriate theme.
// @import 'themes/red';
// @import 'themes/blue';
// @import 'themes/black';
// @import 'themes/green';
@import "themes/red";

$theme-yellow: #fef200;

$departments-bg: black;

$accent-color: #cc3333;
