/*
// .site
*/
@import "../variables";

.site {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.site__header {
    flex-shrink: 0;
}
.site__body {
    flex-grow: 1;
}
.site__footer {
    flex-shrink: 0;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}
.alert-danger {
    color: #721c24 !important; 
    background-color: #f8d7da;
    border-color: #f5c6cb;
}