/*
// .product-image
*/
@import '../variables';
@import '../mixins/hacks';


.product-image {}
.product-image__body {
    display: block;
    position: relative;
    padding-bottom: #{65% * (1 / $product-image-ratio)};
    width: 100%;
}
.product-image__img {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: scale-down;

    &,
    .owl-carousel .owl-item & {
        @include only-ie {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
}
.product-image--location--gallery {
    .owl-carousel .owl-item & .product-image__img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}